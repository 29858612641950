/* eslint-disable @next/next/no-html-link-for-pages */
import React from "react";
import {
  faTelegramPlane,
  faMediumM,
  faDiscord,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/fontawesome-free-regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import { DialogContext, DialogName } from "../context/DialogContext";
import Image from "next/image";
import { Link as Scroll } from "react-scroll";
import { useRouter } from "next/router";
import { currentYear } from "../constants";

const navigation = {
  solutions: [
    { name: "", to: "anchor-" },
    { name: "$MAX", to: "anchor-token" },
    { name: "Roadmap", to: "anchor-roadmap" },
    { name: "Press", to: "anchor-blog" },
  ],
  company: [
    { name: "Team", to: "anchor-team" },
    { name: "FAQ", to: "anchor-faq" },
    { name: "Contact", href: "#" },
  ],
};

export default function Footer() {
  const router = useRouter();

  const { setCurrentDialog } = React.useContext(DialogContext);
  const isMainPage = router.route === "/";

  const social = [
    // {
    //   name: "Facebook",
    //   href: "https://www.facebook.com/maxima.horse",
    //   icon: (
    //     <svg
    //       fill="currentColor"
    //       viewBox="0 0 24 24"
    //       className="w-5 h-5"
    //       aria-hidden="true"
    //     >
    //       <path
    //         fillRule="evenodd"
    //         d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
    //         clipRule="evenodd"
    //       />
    //     </svg>
    //   ),
    // },
    {
      name: "Twitter",
      href: "https://twitter.com/MaximaHorse/",
      icon: (
        <svg
          fill="currentColor"
          viewBox="0 0 24 24"
          className="w-5 h-5"
          aria-hidden="true"
        >
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: "Discord",
      href: "https://discord.gg/maxima",
      icon: (
        <FontAwesomeIcon
          className="w-5 h-5 hover:text-maxima-secondary"
          icon={faDiscord}
          size={"1x"}
        />
      ),
    },
    // {
    //   name: "Telegram",
    //   href: "https://t.me/maximahorsenft",
    //   icon: (
    //     <FontAwesomeIcon
    //       className="w-5 h-5 hover:text-maxima-secondary"
    //       icon={faTelegramPlane}
    //       size={"1x"}
    //     />
    //   ),
    // },
    {
      name: "Medium",
      href: "https://medium.com/@maximahorse",
      icon: (
        <FontAwesomeIcon
          className="w-5 h-5 hover:text-maxima-secondary"
          icon={faMediumM}
          size={"1x"}
        />
      ),
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/maximahorse/",
      icon: (
        <svg
          fill="currentColor"
          viewBox="0 0 24 24"
          className="w-5 h-5"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    // {
    //   name: "LinkedIn",
    //   href: "https://www.linkedin.com/company/maxima-racing-syndicate/",
    //   icon: (
    //     <FontAwesomeIcon
    //       className="w-5 h-5 hover:text-maxima-secondary"
    //       icon={faLinkedin}
    //     />
    //   ),
    // },
    // {
    //   name: "Youtube",
    //   href: "https://www.youtube.com/channel/UCym8sdyTvsiJ17RuwxRoT9Q/",
    //   icon: (
    //     <FontAwesomeIcon
    //       className="w-5 h-5 hover:text-maxima-secondary"
    //       icon={faYoutube}
    //       size={"1x"}
    //     />
    //   ),
    // },

    {
      name: "Email",
      href: "mailto:hello@maxima.horse",
      icon: (
        <FontAwesomeIcon
          className="w-5 h-5 hover:text-maxima-secondary"
          icon={faEnvelope as any}
          size={"1x"}
        />
      ),
    },
  ];

  return (
    <>
      <footer className="bg-gray-900" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-32">
            <div className="space-y-8 text-center xl:col-span-1 xl:text-left">
              <Image width={72} height={72} src="/logo-only.png" alt="Maxima" />
              <p className="text-base text-gray-200">
                World first NFT realworld horse racing. Popularizing horse
                ownership and investing them with ease in an easy, fun, and
                affordable way.
              </p>
              <div className="flex justify-center space-x-6 text-gray-400 xl:justify-start">
                {social.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-gray-400 hover:text-maxima-secondary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="sr-only">{item.name}</span>
                    {item.icon}
                  </a>
                ))}
              </div>
            </div>
            <div className="mt-12 xl:mt-0 xl:col-span-2">
              <div className="md:grid md:grid-cols-3 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold tracking-wider text-gray-200 uppercase">
                    Products
                  </h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.solutions.map((item) => {
                      if (!isMainPage) {
                        return (
                          <li key={item.name}>
                            <Link
                              href={`/#${item.to}`}
                              passHref
                              key={item.name}
                            >
                              <span className="text-base text-gray-400 cursor-pointer hover:text-maxima-secondary">
                                {item.name}
                              </span>
                            </Link>
                          </li>
                        );
                      }

                      return (
                        <li key={item.name}>
                          <Scroll
                            href={item.to}
                            to={item.to!}
                            spy={true}
                            smooth={true}
                            offset={-50}
                            duration={500}
                            className="text-base text-gray-400 hover:text-maxima-secondary"
                          >
                            {item.name}
                          </Scroll>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold tracking-wider text-gray-200 uppercase">
                    About us
                  </h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.company.map((item) => {
                      return (
                        <li key={item.name}>
                          {item.name === "Contact" ? (
                            <button
                              onClick={() =>
                                setCurrentDialog(DialogName.Contact)
                              }
                              className="text-base text-gray-400 hover:text-maxima-secondary"
                            >
                              {item.name}
                            </button>
                          ) : !isMainPage ? (
                            <Link
                              href={`/#${item.to}`}
                              passHref
                              key={item.name}
                            >
                              <span className="text-base text-gray-400 cursor-pointer hover:text-maxima-secondary">
                                {item.name}
                              </span>
                            </Link>
                          ) : (
                            <Scroll
                              href={item.to}
                              to={item.to!}
                              spy={true}
                              smooth={true}
                              offset={-50}
                              duration={500}
                              className="text-base text-gray-400 hover:text-maxima-secondary"
                            >
                              {item.name}
                            </Scroll>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold tracking-wider text-gray-200 uppercase">
                    More
                  </h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {/* <li>
                      <a
                        className="text-base text-gray-400 hover:text-maxima-secondary"
                        href="https://whitepaper.maxima.horse"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Whitepaper
                      </a>
                    </li> */}
                    <li>
                      <div className="text-base text-gray-400 hover:text-maxima-secondary">
                        <Link href="/terms-and-conditions">
                          Terms and Conditions
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="text-base text-gray-400 hover:text-maxima-secondary">
                        <Link href="/privacy">Privacy</Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-8 mt-12 border-t border-gray-200">
            <p className="text-base text-center text-gray-400">
              &copy; {currentYear} Maxima Racing Syndicate. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
